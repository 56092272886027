<template>
  <div v-if="serviceRequest" class="flex flex-col h-full overflow-hidden">
    <div>
      <div class="flex justify-between mb-4 text-sm" v-if="residentFullName">
        <div>
          {{ residentFullName }}
        </div>
        <div>
          {{ unitName }}
        </div>
      </div>

      <hr>

      <div class="flex lowercase text-xs mt-2 mb-4">
        <div class="mr-5">
          <span class="text-gray-600 mr-1">{{ $t('sreq.feed.header.status') }}</span>
          <span class="font-600">{{ $t(`sreq.status.${serviceRequest.currentState.id}`) }}</span>
        </div>
        <div class="mr-5">
          <span class="text-gray-600 mr-1">{{ $t('sreq.feed.header.scheduled_for') }}</span>
          <span class="font-600">{{ serviceRequest.schedule || '-' }}</span>
        </div>
        <div class="mr-5">
          <span class="text-gray-600 mr-1">{{ $t('sreq.feed.header.assigned_to') }}</span>
          <span class="font-600">{{ assigneeFullName }}</span>
        </div>
      </div>
    </div>

    <Messenger :chat-id="serviceRequest.chat.id" :initial-type-filter="initialTypeFilter"/>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Messenger from "@/components/sreq/feed/Messenger";
import {MESSAGE_TYPE_NOTE, MESSAGE_TYPE_SIMPLE} from "@/utils/constants/chat";

export default {
  name: "RequestFeed",
  components: {Messenger},
  mixins: [ModalNavigation],
  data() {
    return {
      serviceRequest: null,
      pushDetails: false,
      initialTypeFilter: null,
    };
  },
  computed: {
    residentFullName() {
      if (this.serviceRequest.reporterResidentProfile) {
        return this.toFullName(this.serviceRequest.reporterResidentProfile.resident);
      }

      return null;
    },
    unitName() {
      if (this.serviceRequest.sharedLocation) {
        return null;
      }

      const building = this.serviceRequest.location;
      const unit = building.locations[0];

      return `${building.name}:${unit.name}`.toLowerCase();
    },
    assigneeFullName() {
      return this.serviceRequest.assignee ? this.toFullName(this.serviceRequest.assignee) : '-';
    }
  },
  methods: {
    toFullName(person) {
      return `${person.firstName} ${person.lastName}`.toLowerCase();
    },
  },
  async mounted() {
    const {requestId} = this.$route.params;
    this.$sreqDataProvider.getOne('serviceRequests', {id: requestId}).then(serviceRequest => {
      this.serviceRequest = {
        ...serviceRequest.data,
        ...serviceRequest.metadata
      };

      if (this.pushDetails) {
        this.pushModal({
          template: '',
          routeName: 'sreq.details',
          params: {requestId},
          breadcrumbName: this.serviceRequest.title,
          title: this.serviceRequest.title,
        });
      }
    });

    switch (this.$route.hash) {
      case '#chats':
        this.initialTypeFilter = MESSAGE_TYPE_SIMPLE;
        break;
      case '#notes':
        this.initialTypeFilter = MESSAGE_TYPE_NOTE;
        break;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.pushDetails = from.name === 'sreq.index';
    });
  }
}
</script>
